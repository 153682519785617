import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import PropertyLayout from '../../components/PropertiesSearchPage/components/Layout'
import { PropertyProps } from '../../components/PropertiesSearchPage/data'
import { propertyMapping } from '../../components/PropertiesSearchPage/propertiesMapper'

type PropType = {
  data: { allSitePage: { edges: PropertyProps[] } }
  pageContext: {
    brand: string
  }
}

const PropertiesPage = ({
  data: {
    allSitePage: { edges }
  },
  pageContext: { brand }
}: PropType): ReactElement => {
  return <PropertyLayout propertiesMapped={propertyMapping(edges)} brand={brand} />
}

export const getData = graphql`
  query allBrandsProperties($brand: String) {
    allSitePage(
      filter: {
        context: { brand: { eq: $brand }, type: { eq: "overview" }, category: { eq: "property" }, property: { details: { location: {} } } }
      }
    ) {
      edges {
        node {
          context {
            property {
              details {
                brand
                featured
                floorArea
                id
                imageLogo
                location
                lotArea
                name
                price
                priceRange {
                  highest
                  lowest
                }
                propertyType
                slug
                terrain
              }
              overview {
                images
              }
            }
          }
          path
        }
      }
    }
  }
`

export default PropertiesPage
